import React, { useState } from 'react';
import { useTranslation } from 'next-i18next';
import { CustomIcon } from '@components/ui/Icon';
import { CustomLink, CustomText } from '@components/ui';
import { LANGUAGE_CODE } from 'src/constants';
import { CloseIcon } from 'src/assets/icons';
import { PATH_BRAND } from 'src/routes/paths';
import { AddCartButton } from './cart';
import {
  AconOnly,
  BulletPoint,
  CardBadgeContainer,
  CardBody,
  CardBorder,
  CardCheckBox,
  CardContainer,
  CardContents,
  CardCount,
  CardCreater,
  CardExtension,
  CardFooter,
  CardHeader,
  CardMiddle,
  CardPercentText,
  CardPriceContainer,
  CardRealPriceText,
  CardRealPriceUnitText,
  CardReview,
  CardReviewCount,
  CardTitle,
  CardTitleText,
  DeleteScrap,
} from './styled';
import { DefaultBadge, ExtensionBadge, RankBadge } from './Badge';
import { CardImg } from './cardimg';
import { ProductCardType, TActionName } from './type';
import ViewCount from './count/ViewCount';
import { PromotionTimer } from './Timer';

/* eslint-disable @typescript-eslint/no-unused-vars */
export default function Card({
  languageCode,
  discountPercentage,
  ranking,
  isDisplayCartButton,
  isDisplayOnScrap,
  productViewUrl,
  wishSno,
  goodsNo,
  brandCd,
  isGoods,
  imageUrl,
  subImageUrl,
  brandName,
  title,
  realPrice,
  price,
  reviewCount,
  viewCount,
  extensions,
  badgeName,
  isCart,
  isScrapEdit,
  selectedGoods,
  onChangeCardCheckBox,
  onRemoveScrap,
  onAddCart,
  isPromotionTimer = false,
  promotionEndDate,
  isAconOnly = false,
  isPromotion = false,
  onSale = true,
  isDisplayReviewCount = true,
  isDisplayDiscountText = true,
  isDisableThumbnail = false,
  isBorderRound = false,
  className,
  onClick,
  onDisplay,
  ...rest
}: ProductCardType) {
  // 번역도구
  const { t, i18n } = useTranslation('common');
  const [isMouseOver, setIsMouseOver] = useState(false);

  // 스크랩 페이지(mypage/clip-book)에서 편집 버튼을 누르면 활성화되는 삭제 버튼의 onClick event handler
  const handleDeleteScrapClick = () => {
    onRemoveScrap(goodsNo);
  };

  // 카드 체크박스 클릭 이벤트
  const handleScrapEditCheckbox = (goodsNo: string) => {
    if (isScrapEdit) onChangeCardCheckBox(!checked, goodsNo);
  };

  // 선택된 항목 찾기
  const findGoods = (goodsNo: string) => {
    return selectedGoods?.find((x) => x === goodsNo);
  };

  // mouse over 이벤트
  const handleMouseOver = () => {
    setIsMouseOver(true);
  };
  // mouse leave 이벤트
  const handleMouseLeave = () => {
    setIsMouseOver(false);
  };

  const handleCardClick = (actionName: TActionName) => {
    onClick && onClick({ actionName, title, goodsNo });
  };

  // 체크됨 여부
  const checked = Boolean(findGoods(goodsNo));

  return (
    <CardContainer
      onClick={() => {
        handleScrapEditCheckbox(goodsNo);
      }}
      isDisplayOnScrap={isDisplayOnScrap}
      isScrapEdit={isScrapEdit}
      isChecked={checked}
      isShowContainer={isGoods}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      isDisableThumbnail={isDisableThumbnail}
      isBorderRound={isBorderRound}
      className={`mb-12 ${className}`}
      {...rest}
    >
      {isScrapEdit && (isMouseOver || checked) && <CardBorder />}
      <CardHeader isDisabled={isScrapEdit}>
        {isDisplayOnScrap && !isScrapEdit && isMouseOver && (
          // 스크랩 지우기 버튼 (mypage/clip-book 에서 활성화됨)
          <DeleteScrap onClick={handleDeleteScrapClick}>
            <CloseIcon size={18} color={'#fff'} weight={'normal'} />
          </DeleteScrap>
        )}
        {isScrapEdit && (
          <CardCheckBox isChecked={checked}>{checked && <CustomIcon src="/icons/goods/checked.svg" alt="checked" title="checked" width="16px" height="16px" />}</CardCheckBox>
        )}
        <CardImg
          title={title}
          href={productViewUrl}
          mainImageURL={imageUrl}
          subImageURL={subImageUrl}
          goodsNo={goodsNo}
          wishSno={wishSno}
          isDisplayOnScrap={isDisplayOnScrap}
          isScrapEdit={isScrapEdit}
          onSale={onSale}
          onClick={handleCardClick}
        />
        <CardBadgeContainer>
          {ranking && <RankBadge>{ranking}</RankBadge>}
          {badgeName && !ranking && <DefaultBadge className={'top-2'}>{badgeName}</DefaultBadge>}
        </CardBadgeContainer>
        {onSale &&
          isPromotionTimer &&
          promotionEndDate &&
          !promotionEndDate.startsWith('1970-01-01') &&
          // 2일 이내
          new Date(promotionEndDate) < new Date(Date.now() + 1000 * 3600 * 48) && <PromotionTimer targetDate={new Date(promotionEndDate.replace(/-/gi, '/'))} />}
      </CardHeader>
      {isGoods && (
        <CardContents isDisabled={isScrapEdit}>
          <CardBody>
            <CardMiddle>
              <CardCreater
                onClick={() => {
                  handleCardClick('brand');
                }}
              >
                <CustomLink href={PATH_BRAND.view(brandCd)} newWindow>
                  {brandName}
                </CustomLink>
              </CardCreater>
              <CardCount>
                {isDisplayReviewCount && (
                  <CardReview>
                    <CustomIcon src="/icons/goods/comments-icon.svg" alt="commentsIcon" title="commentsIcon" width="12px" height="12px" />
                    <CardReviewCount>{reviewCount.toLocaleString('ko-KR')}</CardReviewCount>
                  </CardReview>
                )}
                <ViewCount count={viewCount} />
              </CardCount>
            </CardMiddle>
            {
              // AconOnly 카테고리(243)에 속해있을 경우 Acon Only 텍스트 표시
              isAconOnly && (
                <AconOnly>
                  <CustomIcon src="/icons/goods/acon-only-icon.png" alt="aconOnly" title="aconOnly" width="11px" height="11px" className={'mr-1'} />
                  <CustomText weight={500} size={11} color={'#D343EB'}>
                    {t('product.aconOnly')}
                  </CustomText>
                </AconOnly>
              )
            }
            <CardTitle
              onClick={() => {
                handleCardClick('title');
              }}
            >
              <CustomLink href={productViewUrl} newWindow>
                <CardTitleText lang={i18n.language}>{title}</CardTitleText>
              </CustomLink>
            </CardTitle>
            <CardPriceContainer>
              {price <= 0 ? (
                <CardRealPriceText>FREE</CardRealPriceText>
              ) : (
                <>
                  {discountPercentage > 0 && <CardPercentText data-active={isPromotion}>{discountPercentage}%</CardPercentText>}
                  <CardRealPriceText>
                    {i18n.language === LANGUAGE_CODE.KO ? (
                      <div className={'flex items-baseline'}>
                        {price.toLocaleString('ko-KR')}
                        <CardRealPriceUnitText size={12}>{t('price.unit')}</CardRealPriceUnitText>
                      </div>
                    ) : i18n.language === LANGUAGE_CODE.JP ? (
                      `${price.toLocaleString('ko-KR')}${t('price.unit')}`
                    ) : (
                      `${t('price.unit')}${price.toLocaleString('ko-KR')}`
                    )}
                  </CardRealPriceText>
                  {
                    // 고도몰 혜택이 적용되어 있을 경우 "추가 할인" 문구 표시
                    isPromotion && isDisplayDiscountText && (
                      <div className={'flex items-baseline'}>
                        <BulletPoint color={'#B16EBC'}>•</BulletPoint>
                        <CustomText lineHeight={'100%'} weight={400} size={12} color={'#B16EBC'}>
                          {t('product.ExtraDiscount')}
                        </CustomText>
                      </div>
                    )
                  }
                </>
              )}
            </CardPriceContainer>
          </CardBody>
          <CardFooter>
            <CardExtension>
              {(extensions ?? []).map((extension, idx) => (
                <div
                  key={idx}
                  onClick={() => {
                    handleCardClick('extension');
                  }}
                >
                  <CustomLink href={productViewUrl}>
                    <ExtensionBadge name={extension.replace('.', '')} />
                  </CustomLink>
                </div>
              ))}
            </CardExtension>
            {
              //카트 담기 버튼
              isDisplayCartButton && (
                <AddCartButton
                  isScrapEdit={isScrapEdit}
                  onSale={onSale}
                  goodsNo={goodsNo}
                  title={title}
                  price={price}
                  onAddCart={onAddCart}
                  {...(!isScrapEdit && {
                    'data-mixpanel-action': 'click',
                    'data-mixpanel-evt': 'Add cart from scrap',
                    'data-mixpanel-product_id': goodsNo,
                  })}
                />
              )
            }
          </CardFooter>
        </CardContents>
      )}
    </CardContainer>
  );
}
